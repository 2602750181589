* {
  box-sizing: border-box;
}
html,
body,
#root,
#root > div {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: hard-light;
}

.menu-wrapper {
  padding: 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.menu-option {
  font-family: sans-serif;
  display: inline-block;
  margin: 0 0 0 1rem;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
}
